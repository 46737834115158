
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelDespesaRateada extends PageBase { 
    service = new RelatorioService();
    item:any = {};

    overlay: boolean = false; 
    filter:any = {};
 
    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;  

        this.service.ListarRelatorioDespesaRateada(this.filter.empreendimentoId, this.filter.fornecedorId, this.filter.empresaId, this.filter.formaPagamentoId, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataBaixaInicial, this.filter.dataBaixaFinal, this.filter.dataEmissaoInicial,  this.filter.dataEmissaoFinal, this.filter.contaGerencialId)
            .then(
                res => {
                    this.item = res.data;
                 },
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{ 
                this.overlay = false;
            }
        );
    } 
}
